import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DataAccessHelpersService } from './data-access-helpers.service';

@Injectable({
  providedIn: "root",
})
export class ConteneurAccessService {
  /**
   * Returns all containers belongs to a parc in realtime
   * @param idParc Parc Id
   */
  getAllContainer(idParc: string): Observable<any[]> {
    return this.dhs.getCollection(`yards/${idParc}/containers`);
  }
  /**
   * Returns all containers belongs to a parc
   * @param idParc Parc Id
   */
  getAllCtr(idParc: string): Promise<IContainer[]> {
    return this.dhs.getCollectionOnce(`yards/${idParc}/containers`);
  }
  getSpecificCtr(idParc: string, numSerie: string) {
    return this.afs.doc(`yards/${idParc}/containers/${numSerie}`).ref.get();
  }
  /**
   * Returns container data by serial number
   * @param idParc Parc Id
   * @param numSerie Container serial number
   */
  getContainerData(idParc: string, numSerie: string): Promise<IContainer> {
    return this.dhs.getDocumentOnce(`yards/${idParc}/containers/${numSerie}`);
  }
  updateContainer(idParc: string, numSerie: string, data) {
    return this.afs.doc(`yards/${idParc}/containers/${numSerie}`).update(data);
  }

  getClientsWithPtiValidity = async (parc: string) => {
    try {
      const clients = await this.afs.firestore
        .collection(`yards/${parc}/clients`)
        .get();
      let dic = {};
      clients.docs.forEach((doc) => {
        if (doc.data().ptiValidity) {
          dic = { ...dic, [doc.id]: doc.data().ptiValidity };
        }
      });
      return [dic, Object.keys(dic).length];
    } catch (error) {
      console.log("Something bad happend here", error);
    }
  };

  constructor(
    private dhs: DataAccessHelpersService,
    private afs: AngularFirestore
  ) {}
}
